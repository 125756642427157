import React, { useState, useEffect } from "react";
import RouteMap from "./RouteMap";

interface Tech {
    id: number;
    name: string;
    google_address?: string;
    google_lat?: number;
    google_lng?: number;
}
interface Market {
    id: number;
    name: string;
}
interface Job {
    id: number;
    google_lat: number;
    google_lng: number;
    claim_number: string;
    google_address: string;
    sf_created_at: string;
    start_date: string;
}
interface UserAddress {
    id: number;
    google_lat: number;
    google_lng: number;
    google_address: string;
}
interface Route {
    jobs: Array<{
        id: number;
        google_lat: number;
        google_lng: number;
        claim_number: string;
        google_address: string;
        start_date: string;
    }>;
    polyline: string;
    duration: string;
    distance: string;
    route_id: number;
}
interface LocationPinButtonProps {
    onLocationSet: (address: UserAddress | null) => void;
}
interface TechLocationPinButtonProps {
    onLocationSet: (address: UserAddress | null) => void;
    userAddress: UserAddress | null;
    selectedTech: number | null;
}

const TechRoutePlanner: React.FC = () => {
    const [techs, setTechs] = useState<Tech[]>([]);
    const [selectedTech, setSelectedTech] = useState<number | null>(null);
    const [markets, setMarkets] = useState<Market[]>([]);
    const [selectedMarket, setSelectedMarket] = useState<number | null>(null);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [originalJobs, setOriginalJobs] = useState<Job[]>([]);
    const [selectedJobs, setSelectedJobs] = useState<Job[]>([]);
    const [optimizedRoute, setOptimizedRoute] = useState<Route | null>(null);
    const [loading, setLoading] = useState(false);
    const [userAddress, setUserAddress] = useState<UserAddress | null>(null);
    const [showAllJobs, setShowAllJobs] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const filterJobs = (start: string, end: string) => {
        if (!start && !end) return;

        if (!originalJobs.length) {
            setOriginalJobs(jobs);
        }

        const startDateTime = start ? new Date(start) : null;
        const endDateTime = end ? new Date(end) : null;

        const filteredJobs = originalJobs.filter((job) => {
            const jobDate = new Date(job.start_date);
            if (startDateTime && endDateTime) {
                return jobDate >= startDateTime && jobDate <= endDateTime;
            } else if (startDateTime) {
                return jobDate >= startDateTime;
            } else if (endDateTime) {
                return jobDate <= endDateTime;
            }
            return true;
        });

        setJobs(filteredJobs);
        setSelectedJobs([]);
    };

    const filterByDate = (offset: number) => {
        if (!originalJobs.length) {
            setOriginalJobs(jobs);
        }

        const date = new Date();
        date.setDate(date.getDate() + offset - 1);

        const filteredJobs = originalJobs.filter((job) => {
            const jobDate = new Date(job.start_date);
            return jobDate.toDateString() === date.toDateString();
        });

        setJobs(filteredJobs);
        setSelectedJobs([]);
    };

    const resetJobs = () => {
        setJobs(originalJobs);
        setSelectedJobs([]);
        setStartDate("");
        setEndDate("");
    };

    const SelectedJobsIndicator = ({ selectedJobs, job }) => {
        const index = selectedJobs.findIndex((j) => j.id === job.id);

        return (
            <div className="absolute top-4 right-4 px-2 rounded-full bg-blue-500 text-white">
                {index !== -1 ? `${index + 1}` : ""}
            </div>
        );
    };

    const TechLocationPinButton: React.FC<TechLocationPinButtonProps> = ({
        onLocationSet,
        userAddress,
        selectedTech,
    }) => {
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState<string | null>(null);

        const toggleCurrentLocation = () => {
            if (userAddress) {
                onLocationSet(null); // Changed from setUserAddress
            } else {
                getCurrentLocation();
            }
        };

        const getCurrentLocation = () => {
            if (selectedTech) {
                setLoading(true);
                fetch(`/api/techs/${selectedTech}`)
                    .then((res) => res.json())
                    .then((tech: Tech) => {
                        if (tech.google_address) {
                            const userAddress: UserAddress = {
                                id: tech.id,
                                google_lat: Number(tech.google_lat) ?? 0,
                                google_lng: Number(tech.google_lng) ?? 0,
                                google_address: tech.google_address,
                            };
                            onLocationSet(userAddress);
                        } else {
                            onLocationSet(null); // Changed from setUserAddress
                        }
                    })
                    .catch((err) => setError("Failed to load tech data"))
                    .finally(() => setLoading(false));
            }
        };

        return (
            <button
                onClick={toggleCurrentLocation}
                disabled={loading}
                className={`flex items-center gap-2 px-4 py-2 rounded shadow disabled:opacity-50 ${
                    userAddress
                        ? "bg-blue-500 text-white"
                        : "bg-white dark:bg-gray-800"
                }`}
            >
                <i className="fa-solid fa-map-pin"></i>
            </button>
        );
    };

    const LocationPinButton: React.FC<LocationPinButtonProps> = ({
        onLocationSet,
    }) => {
        const [loading, setLoading] = useState(false);

        const toggleCurrentLocation = () => {
            if (userAddress) {
                setUserAddress(null);
            } else {
                getCurrentLocation();
            }
        };

        const getCurrentLocation = () => {
            setLoading(true);

            if (!navigator.geolocation) {
                alert("Geolocation is not supported by your browser");
                setLoading(false);
                return;
            }

            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    try {
                        const response = await fetch(
                            `https://api.geoapify.com/v1/geocode/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&apiKey=67718b5929f54d5eaadb782592490ac6`
                        );
                        const data = await response.json();

                        const userAddress: UserAddress = {
                            id: 0,
                            google_lat: position.coords.latitude,
                            google_lng: position.coords.longitude,
                            google_address:
                                data.features[0].properties.formatted,
                        };

                        onLocationSet(userAddress);
                    } catch (error) {
                        alert("Error getting address from coordinates");
                    } finally {
                        setLoading(false);
                    }
                },
                (error) => {
                    alert("Error getting location: " + error.message);
                    setLoading(false);
                }
            );
        };

        return (
            <button
                onClick={toggleCurrentLocation}
                disabled={loading}
                className={`flex items-center gap-2 px-4 py-2 rounded shadow disabled:opacity-50 ${
                    userAddress
                        ? "bg-blue-500 text-white"
                        : "bg-white dark:bg-gray-800"
                }`}
            >
                <i className="fa-solid fa-map-pin"></i>
            </button>
        );
    };

    // Fetch techs on mount
    useEffect(() => {
        const fetchTechs = async () => {
            try {
                const response = await fetch("/api/techs");
                if (!response.ok) throw new Error("Failed to fetch techs");
                const data = await response.json();
                setTechs(data);
            } catch (error) {
                console.error("Error fetching techs:", error);
            }
        };

        fetchTechs();
    }, []);

    // Fetch markets on mount
    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const response = await fetch("/api/markets/index");
                if (!response.ok) throw new Error("Failed to fetch markets");
                const data = await response.json();
                setMarkets(data);
            } catch (error) {
                console.error("Error fetching markets:", error);
            }
        };

        fetchMarkets();
    }, []);

    // Fetch tech's jobs when a tech is selected
    useEffect(() => {
        if (!selectedTech) return;
        setSelectedMarket(null);
        setUserAddress(null);

        const fetchJobs = async () => {
            setLoading(true);
            try {
                const response = await fetch(`/api/techs/${selectedTech}/jobs`);
                if (!response.ok) throw new Error("Failed to fetch jobs");
                const data = await response.json();
                setJobs(data);
                setOriginalJobs(data);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [selectedTech]);

    // Fetch markets jobs when selected
    useEffect(() => {
        if (!selectedMarket) return;
        setSelectedTech(null);
        setUserAddress(null);

        const fetchJobs = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `/api/markets/${selectedMarket}/jobs`
                );
                if (!response.ok) throw new Error("Failed to fetch jobs");
                const data = await response.json();
                setJobs(data);
                setOriginalJobs(data);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [selectedMarket]);

    const handleJobSelect = (job: Job) => {
        setSelectedJobs((prev) => {
            const exists = prev.findIndex((j) => j.id === job.id);
            if (exists >= 0) {
                const copy = [...prev];
                copy.splice(exists, 1);
                return copy;
            }
            return prev.length >= 10 ? prev : [...prev, job];
        });
    };

    const createRoute = async () => {
        if (selectedJobs.length < 2) {
            alert("Please select at least 2 jobs");
            return;
        }

        setLoading(true);
        try {
            const response = await fetch("/api/drive-time-routes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_address: userAddress,
                    tech_id: selectedTech ?? 980508665,
                    job_ids: selectedJobs.map((job) => job.id),
                }),
            });

            if (!response.ok) throw new Error("Failed to create route");
            const route = await response.json();
            setOptimizedRoute(route);
        } catch (error) {
            console.error("Error creating route:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedTech || selectedMarket) {
            setShowAllJobs(true);
        }
    }, [selectedTech, selectedMarket]);

    useEffect(() => {
        if (optimizedRoute) {
            setShowAllJobs(false);
        }
    }, [optimizedRoute]);

    return (
        <div className="pb-4">
            <div className="h-full w-full bg-[url('/images/map-background.svg')] bg-cover bg-center bg-no-repeat flex flex-col items-center text-center">
                <h1 className="text-2xl font-semibold mt-4 dark:text-white">
                    Tech Route Planner
                </h1>
                <p className="text-gray-500 dark:text-gray-200 mt-0 mb-2">
                    Select a tech or market to view available jobs and plan a
                    route
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-4">
                    {error && <div className="text-red-500">{error}</div>}

                    {/* Tech Selection */}
                    <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 dark:text-white p-4 rounded-lg shadow w-[350px]">
                        <div className="flex items-center gap-4 mb-4">
                            <h2 className="text-lg font-semibold">
                                Select Tech
                            </h2>
                            <TechLocationPinButton
                                onLocationSet={setUserAddress}
                                userAddress={userAddress}
                                selectedTech={selectedTech}
                            />
                        </div>
                        <select
                            className="bg-white dark:bg-gray-800 w-full p-2 border border-gray-200 dark:border-gray-600 rounded"
                            value={selectedTech || ""}
                            onChange={(e) => {
                                setSelectedTech(Number(e.target.value));
                                setSelectedJobs([]);
                                setOptimizedRoute(null);
                            }}
                        >
                            <option value="">Select a tech...</option>
                            {techs.map((tech) => (
                                <option
                                    key={tech.id}
                                    value={tech.id}
                                    id={tech.id.toString()}
                                >
                                    {tech.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Market Selection */}
                    <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 dark:text-white p-4 rounded-lg shadow w-[350px]">
                        <div className="flex items-center gap-4 mb-4">
                            <h2 className="text-lg font-semibold">
                                Select Market
                            </h2>
                        </div>
                        <select
                            className="bg-white dark:bg-gray-800 w-full p-2 border border-gray-200 dark:border-gray-600 rounded"
                            value={selectedMarket || ""}
                            onChange={(e) => {
                                setSelectedMarket(Number(e.target.value));
                                setSelectedJobs([]);
                                setOptimizedRoute(null);
                            }}
                        >
                            <option value="">Select a market...</option>
                            {markets.map((market) => (
                                <option key={market.id} value={market.id}>
                                    {market.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 mt-4 gap-4">
                {/* Jobs Selection */}
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 dark:text-white p-4 rounded-lg shadow lg:col-span-4">
                    <div className="flex flex-col mb-4">
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex items-center gap-2">
                                <h2 className="text-lg font-semibold">
                                    Select Jobs
                                </h2>
                                <LocationPinButton
                                    onLocationSet={setUserAddress}
                                />
                                <button
                                    onClick={() => resetJobs()}
                                    className="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded bg-blue-500 text-white"
                                >
                                    Clear Filters
                                </button>
                            </div>
                            <span className="text-sm text-gray-500">
                                {selectedJobs.length} selected
                            </span>
                        </div>
                        <div className="flex items-center gap-2 mb-4">
                            <button
                                onClick={() => filterByDate(-1)}
                                className="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded hover:bg-gray-200"
                            >
                                Yesterday
                            </button>
                            <button
                                onClick={() => filterByDate(0)}
                                className="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded hover:bg-gray-200"
                            >
                                Today
                            </button>
                            <button
                                onClick={() => filterByDate(1)}
                                className="border border-gray-200 dark:border-gray-600 px-2 py-1 rounded hover:bg-gray-200"
                            >
                                Tomorrow
                            </button>
                        </div>
                        <div className="flex items-center gap-2">
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value);
                                    filterJobs(e.target.value, endDate);
                                }}
                                className="bg-white dark:bg-gray-800 border border-gray-300 rounded px-2 py-1"
                            />
                            <span>to</span>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                    setEndDate(e.target.value);
                                    filterJobs(startDate, e.target.value);
                                }}
                                className="bg-white dark:bg-gray-800 border border-gray-300 rounded px-2 py-1"
                            />
                        </div>
                    </div>

                    <div className="space-y-2 overflow-y-auto max-h-[450px]">
                        {jobs.map((job) => (
                            <div
                                key={job.id}
                                data-job-id={job.id}
                                onClick={() => handleJobSelect(job)}
                                className={`p-4 border border-gray-200 dark:border-gray-600 rounded cursor-pointer relative ${
                                    selectedJobs.find((j) => j.id === job.id)
                                        ? "bg-blue-50 dark:bg-blue-900 border-blue-200 dark:border-blue-500"
                                        : "bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                                }`}
                            >
                                <div className="font-medium">
                                    {job.claim_number}
                                </div>
                                <div className="text-sm text-gray-500 dark:text-gray-200">
                                    {job.google_address}
                                </div>
                                <div className="text-sm text-gray-500 dark:text-gray-200">
                                    Start Date: {job.start_date}
                                </div>
                                <SelectedJobsIndicator
                                    selectedJobs={selectedJobs}
                                    job={job}
                                />
                            </div>
                        ))}
                    </div>

                    <button
                        onClick={createRoute}
                        disabled={selectedJobs.length < 2 || loading}
                        className="w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                    >
                        {loading ? "Creating Route..." : "Create Route"}
                    </button>
                </div>
                {/* Right Panel - Map */}
                <div className="lg:col-span-8">
                    {jobs.length > 0 ? (
                        showAllJobs ? (
                            <RouteMap
                                jobs={jobs}
                                user_address={userAddress}
                                selectedJobs={selectedJobs}
                                handleJobSelect={handleJobSelect}
                            />
                        ) : (
                            <RouteMap
                                route={optimizedRoute || undefined}
                                user_address={userAddress}
                            />
                        )
                    ) : (
                        <div className="w-full h-[500px] flex items-center justify-center bg-gray-50 dark:bg-gray-800 rounded-lg">
                            <div className="text-center text-gray-500 dark:text-white">
                                Select tech or market to see available jobs
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TechRoutePlanner;
