import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Loader } from "@googlemaps/js-api-loader";

interface UserAddress {
    id: number;
    google_lat: number;
    google_lng: number;
    google_address: string;
}
interface RouteMapProps {
    route?: {
        jobs: Array<{
            id: number;
            google_lat: number;
            google_lng: number;
            claim_number: string;
            google_address: string;
            start_date: string;
        }>;
        polyline: string;
        duration: string;
        distance: string;
        route_id: number;
    };
    jobs?: Array<{
        id: number;
        google_lat: number;
        google_lng: number;
        claim_number: string;
        google_address: string;
        start_date: string;
    }>;
    selectedJobs?: Array<{
        id: number;
        google_lat: number;
        google_lng: number;
        claim_number: string;
        google_address: string;
        start_date: string;
    }>;
    user_address: UserAddress | null;
    handleJobSelect?: (job: any) => void;
}

const RouteMap: React.FC<RouteMapProps> = ({
    route,
    jobs,
    selectedJobs = [],
    user_address,
    handleJobSelect,
}) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [copied, setCopied] = useState(false);

    const MapLinkButtons = ({ route }) => {
        const mapUrl = `https://www.google.com/maps/dir/current+location/${route.jobs
            .map((job) => `${job.google_lat},${job.google_lng}`)
            .join("/")}`;

        const copyLink = () => {
            navigator.clipboard.writeText(mapUrl);
            setCopied(true);
        };

        return (
            <div className="flex gap-2">
                <a
                    href={mapUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-900"
                >
                    Open in Google Maps
                </a>
                <button
                    onClick={copyLink}
                    className={
                        copied
                            ? "bg-white px-4 py-2 rounded shadow hover:bg-blue-900 text-blue-500 border border-blue-500"
                            : "bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-900"
                    }
                >
                    {copied ? "Copied!" : "Copy Link"}
                </button>
            </div>
        );
    };

    // Fetch API key and initialize map
    useEffect(() => {
        const initMap = async () => {
            if (!jobs?.length && !route?.polyline) return;

            try {
                const response = await fetch("/api/config/maps");
                if (!response.ok)
                    throw new Error("Failed to fetch Maps config");
                const { apiKey } = await response.json();

                const loader = new Loader({
                    apiKey,
                    version: "weekly",
                    libraries: ["places", "geometry", "marker"],
                });

                const google = await loader.load();

                if (!mapRef.current) return;

                const initialLat =
                    jobs?.[0]?.google_lat || route?.jobs[0]?.google_lat;
                const initialLng =
                    jobs?.[0]?.google_lng || route?.jobs[0]?.google_lng;

                const mapInstance = new google.maps.Map(mapRef.current, {
                    center: {
                        lat: Number(initialLat),
                        lng: Number(initialLng),
                    },
                    zoom: 8,
                });

                setMap(mapInstance);
                setLoading(false);
            } catch (err) {
                setError(
                    err instanceof Error
                        ? err.message
                        : "Failed to initialize map"
                );
                setLoading(false);
            }
        };

        initMap();
    }, [jobs, route]);

    // Update route when map services are ready
    useEffect(() => {
        if (!map) return;

        // Clear existing markers
        const markers: google.maps.Marker[] = [];

        if (route?.polyline) {
            // Draw route mode - existing route drawing code
            const path = google.maps.geometry.encoding.decodePath(
                route.polyline
            );
            new google.maps.Polyline({
                path,
                geodesic: true,
                strokeColor: "#3f83f8",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map,
            });

            route.jobs.forEach((job, index) => {
                if (!job.google_lat || !job.google_lng) return;

                const marker = new google.maps.Marker({
                    position: {
                        lat: Number(job.google_lat),
                        lng: Number(job.google_lng),
                    },
                    map,
                    icon: {
                        path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
                        fillColor: "#87CEEB",
                        fillOpacity: 1,
                        strokeColor: "#000000",
                        strokeWeight: 1,
                        scale: 0.06,
                        anchor: new google.maps.Point(192, 512),
                        labelOrigin: new google.maps.Point(192, 192),
                    },
                    label: {
                        text: (index + 1).toString(),
                        color: "#FFFFFF",
                        fontSize: "14px",
                    },
                });

                markers.push(marker);

                marker.addListener("click", () => {
                    const jobElement = document.querySelector(
                        `[data-job-id="${job.id}"]`
                    );
                    if (jobElement) {
                        jobElement.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                        jobElement.classList.remove("bg-blue-50");
                        jobElement.classList.add("bg-yellow-100");
                        setTimeout(() => {
                            jobElement.classList.remove("bg-yellow-100");
                            jobElement.classList.add("bg-blue-50");
                        }, 3000);
                    }
                });
            });
        } else if (jobs) {
            // Show all jobs mode
            jobs.forEach((job) => {
                const isSelected = selectedJobs.some((sj) => sj.id === job.id);

                const marker = new google.maps.Marker({
                    position: {
                        lat: Number(job.google_lat),
                        lng: Number(job.google_lng),
                    },
                    map,
                    icon: {
                        path: "M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z",
                        fillColor: isSelected ? "#87CEEB" : "#f3f3f3",
                        fillOpacity: 1,
                        strokeColor: "#000000",
                        strokeWeight: 1,
                        scale: 0.06,
                        anchor: new google.maps.Point(192, 512),
                        labelOrigin: new google.maps.Point(192, 192),
                    },
                });

                markers.push(marker);

                marker.addListener("click", () => {
                    const jobElement = document.querySelector(
                        `[data-job-id="${job.id}"]`
                    );
                    if (jobElement) {
                        jobElement.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                        jobElement.classList.remove("bg-blue-50");
                        jobElement.classList.add("bg-yellow-100");
                        setTimeout(() => {
                            jobElement.classList.remove("bg-yellow-100");
                            jobElement.classList.add("bg-blue-50");
                        }, 3000);
                    }
                    handleJobSelect && handleJobSelect(job);
                });
            });
        }

        // Add user location marker if available
        if (user_address) {
            const userInfoWindow = new google.maps.InfoWindow({
                content: ReactDOMServer.renderToString(
                    <div>
                        <p>
                            <strong>Current Location</strong>
                        </p>
                        <p>
                            <strong>Address:</strong>{" "}
                            {user_address.google_address}
                        </p>
                    </div>
                ),
            });

            const userMarker = new google.maps.Marker({
                position: {
                    lat: user_address.google_lat,
                    lng: user_address.google_lng,
                },
                map,
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: "#3f83f8",
                    fillOpacity: 1,
                    strokeColor: "#FFFFFF",
                    strokeWeight: 1,
                    scale: 12,
                },
                label: {
                    text: "U",
                    color: "#FFFFFF",
                },
            });

            userMarker.addListener("click", () => {
                userInfoWindow.open(map, userMarker);
            });
            markers.push(userMarker);
        }

        // Fit bounds
        const bounds = new google.maps.LatLngBounds();
        if (route?.polyline) {
            const path = google.maps.geometry.encoding.decodePath(
                route.polyline
            );
            path.forEach((point) => bounds.extend(point));
        } else if (jobs) {
            jobs.forEach((job) => {
                bounds.extend({
                    lat: Number(job.google_lat),
                    lng: Number(job.google_lng),
                });
            });
        } else if (selectedJobs) {
            selectedJobs.forEach((job) => {
                bounds.extend({
                    lat: Number(job.google_lat),
                    lng: Number(job.google_lng),
                });
            });
            map.setOptions({
                center: {
                    lat: Number(
                        selectedJobs?.[selectedJobs.length - 1]?.google_lat
                    ),
                    lng: Number(
                        selectedJobs?.[selectedJobs.length - 1]?.google_lng
                    ),
                },
                zoom: 8,
            });
        }

        map.fitBounds(bounds);

        if (user_address) {
            map.setOptions({
                center: {
                    lat: user_address.google_lat,
                    lng: user_address.google_lng,
                },
                zoom: 5,
            });
        }

        // Cleanup function
        return () => {
            markers.forEach((marker) => marker.setMap(null));
        };
    }, [map, route, jobs, selectedJobs, user_address]);

    if (error) {
        return (
            <div className="w-full h-[600px] flex items-center justify-center bg-red-50 text-red-600 rounded-lg">
                <div className="text-center">
                    <p className="font-semibold">Error loading map</p>
                    <p className="text-sm">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-[720px] relative rounded-lg overflow-hidden shadow-lg">
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                    <div className="text-center">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500 mb-2"></div>
                        <p>Loading map...</p>
                    </div>
                </div>
            )}
            <div ref={mapRef} className="w-full h-full" />
            {route && (
                <div className="absolute bottom-0 left-0 right-0 p-4 flex items-center gap-2 bg-white bg-opacity-75">
                    <p className="font-semibold">
                        {route.jobs.length} Stops | {route.duration} |{" "}
                        {route.distance}
                    </p>
                    <MapLinkButtons route={route} />
                </div>
            )}
        </div>
    );
};

export default RouteMap;
