import React from "react";
import { createRoot } from "react-dom/client";
import DateTimeSelector from "./components/DateTimeSelector";
import EmailTemplatesDashboard from "./components/EmailTemplatesDashboard";
import RouteMap from "./components/RouteMap";
import TechRoutePlanner from "./components/TechRoutePlanner";
import TechAddressForm from "./components/TechAddressForm";
import PaymentPortal from "./components/PaymentPortal";
import ViewSingleInvoice from "./components/ViewSingleInvoice";
import LocationCapture from "./components/LocationCapture";

// Mount scheduler component
if (document.getElementById("scheduler")) {
    const schedulerRoot = createRoot(document.getElementById("scheduler"));
    schedulerRoot.render(<DateTimeSelector />);
}

// Mount email template component
if (document.getElementById("email-template-dashboard")) {
    const emailTemplateRoot = createRoot(
        document.getElementById("email-template-dashboard")
    );
    emailTemplateRoot.render(<EmailTemplatesDashboard />);
}

// Mount route planner component
if (document.getElementById("tech-route-planner")) {
    const techRoutePlannerRoot = createRoot(
        document.getElementById("tech-route-planner")
    );
    techRoutePlannerRoot.render(<TechRoutePlanner />);
}

// Mount route map component
if (document.getElementById("route-map")) {
    const routeMapRoot = createRoot(document.getElementById("route-map"));
    routeMapRoot.render(<RouteMap />);
}

// Mount address form component
const techAddressForms = document.querySelectorAll("[id^='tech-address-form']");

if (techAddressForms.length > 0) {
    async function mountComponents() {
        try {
            const res = await fetch("/api/techs");
            const techs = await res.json();

            techAddressForms.forEach((element) => {
                const techId = element.dataset.techId;
                const root = createRoot(element);
                root.render(<TechAddressForm techId={techId} techs={techs} />);
            });
        } catch (err) {
            console.error("Failed to load tech data");
        }
    }

    mountComponents();
}

if (document.getElementById("payment-portal")) {
    const paymentPortalElement = document.getElementById("payment-portal");
    const paymentPortalRoot = createRoot(paymentPortalElement);
    const jobId = paymentPortalElement.dataset.jobId;
    const stripeKey = paymentPortalElement.dataset.stripeKey;
    paymentPortalRoot.render(
        <PaymentPortal jobId={jobId} stripeKey={stripeKey} />
    );
}

if (document.getElementById("invoice-view")) {
    const invoiceViewElement = document.getElementById("invoice-view");
    const jobId = invoiceViewElement.dataset.jobId;
    const invoiceViewRoot = createRoot(invoiceViewElement);
    invoiceViewRoot.render(<ViewSingleInvoice jobId={jobId} />);
}

if (document.getElementById("location-capture")) {
    async function captureLocation() {
        try {
            const res = await fetch("/api/techs");
            const techs = await res.json();

            const locationCaptureElement =
                document.getElementById("location-capture");
            if (!locationCaptureElement) return;

            const techId = parseInt(
                locationCaptureElement?.dataset.techId || "0",
                10
            );
            if (!techId) {
                console.error("No tech ID provided");
                return;
            }

            const locationCaptureRoot = createRoot(locationCaptureElement);
            locationCaptureRoot.render(
                <LocationCapture techId={techId} techs={techs} />
            );
        } catch (err) {
            console.error("Failed to load tech data:", err);
        }
    }

    captureLocation();
}
