import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
    PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { Customer, Job, Invoice } from "../types/types";
import InvoiceDisplay from "./InvoiceDisplay";

interface InvoiceData {
    invoice: Invoice;
    job: Job;
    customer: Customer;
}

interface PaymentPortalProps {
    jobId: string;
    stripeKey: string;
}

// Payment Form Component
const PaymentForm = ({
    invoiceData,
    jobId,
}: {
    invoiceData: InvoiceData;
    jobId: string;
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState("");
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [cardElement, setCardElement] = useState<any>(null);

    useEffect(() => {
        if (elements) {
            const card = elements.getElement(CardElement);
            setCardElement(card);
        }
    }, [elements]);

    useEffect(() => {
        // Check if dark mode is enabled
        const isDark = document.documentElement.classList.contains("dark");
        setIsDarkMode(isDark);

        // Optional: Listen for theme changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === "class") {
                    setIsDarkMode(
                        document.documentElement.classList.contains("dark")
                    );
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ["class"],
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: "US",
                currency: "usd",
                total: {
                    label: `Invoice ${invoiceData.invoice.invoice_number}`,
                    amount: Math.round(invoiceData.invoice.total * 100), // Convert to cents
                },
                requestPayerEmail: true,
                requestPayerName: true,
            });

            pr.canMakePayment().then((result) => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });

            pr.on("paymentmethod", async (e) => {
                setProcessing(true);
                setError(null);

                try {
                    const response = await fetch(
                        `/api/jobs/${jobId}/create-payment-intent`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                amount: invoiceData.invoice.total,
                                email: e.payerEmail,
                            }),
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }

                    const { clientSecret } = await response.json();

                    const { error: confirmError, paymentIntent } =
                        await stripe.confirmCardPayment(
                            clientSecret,
                            { payment_method: e.paymentMethod.id },
                            { handleActions: false }
                        );

                    if (confirmError) {
                        e.complete("fail");
                        setError(confirmError.message || "Payment failed");
                        return;
                    }

                    e.complete("success");
                    if (paymentIntent.status === "succeeded") {
                        setSuccess(true);
                    } else {
                        setError(`Payment status: ${paymentIntent.status}`);
                    }
                } catch (err) {
                    e.complete("fail");
                    setError(
                        err instanceof Error
                            ? err.message
                            : "Payment processing failed"
                    );
                } finally {
                    setProcessing(false);
                }
            });
        }
    }, [
        stripe,
        invoiceData.invoice.total,
        invoiceData.invoice.invoice_number,
        jobId,
    ]);

    const handleCardPayment = async (e: React.FormEvent) => {
        e.preventDefault();
        document.getElementById("hiddenLoadingButton")?.click();

        if (!stripe || !elements) {
            setError("Stripe has not been initialized");
            return;
        }

        // Get card element early and verify it exists
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setError("Card element not found");
            return;
        }

        // Disable form and show processing state
        setProcessing(true);
        setError(null);

        try {
            // Create payment intent first
            const response = await fetch(
                `/api/jobs/${jobId}/create-payment-intent`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        amount: invoiceData.invoice.total,
                        email: email,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to create payment intent");
            }

            const data = await response.json();

            if (!data.clientSecret) {
                throw new Error("No client secret received");
            }

            // Create payment method using the stored card element reference
            const { error: methodError, paymentMethod } =
                await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details: { email },
                });

            if (methodError) {
                throw new Error(methodError.message);
            }

            // Confirm the payment immediately after creating the payment method
            const { error: confirmError, paymentIntent } =
                await stripe.confirmCardPayment(data.clientSecret, {
                    payment_method: paymentMethod.id,
                });

            if (confirmError) {
                throw new Error(confirmError.message);
            }

            if (paymentIntent.status === "succeeded") {
                setSuccess(true);
            } else {
                throw new Error(`Payment status: ${paymentIntent.status}`);
            }
        } catch (err) {
            setError(
                err instanceof Error ? err.message : "Payment processing failed"
            );
        } finally {
            setProcessing(false);
        }
    };

    return (
        <div className="border border-gray-200 dark:border-gray-600 dark:text-white rounded-lg p-6 lg:col-span-4 max-h-[550px]">
            <h2 className="text-2xl font-bold mb-6">Payment Details</h2>

            {success ? (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
                    Payment processed successfully! A receipt has been sent to
                    your email.
                </div>
            ) : (
                <div className="space-y-6">
                    {/* Digital Wallets */}
                    {paymentRequest && !processing && (
                        <div className="mb-6">
                            <PaymentRequestButtonElement
                                options={{
                                    paymentRequest,
                                    style: {
                                        paymentRequestButton: {
                                            height: "44px",
                                            theme: isDarkMode
                                                ? "light"
                                                : "dark",
                                        },
                                    },
                                }}
                                className="w-full"
                            />
                            <div className="relative my-4">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300 dark:border-gray-600"></div>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white dark:bg-gray-900 text-gray-500 dark:text-white">
                                        Or pay with card
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Processing State */}
                    {processing && (
                        <div className="text-center py-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto mb-4"></div>
                            <p className="text-gray-600">
                                Processing your payment...
                            </p>
                        </div>
                    )}

                    {/* Error State */}
                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            <p className="font-bold">Payment Error</p>
                            <p>{error}</p>
                        </div>
                    )}

                    {/* Card Payment Form */}
                    <form onSubmit={handleCardPayment} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium mb-2">
                                Email Address
                            </label>
                            <input
                                type="email"
                                value={invoiceData.customer.customer_email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-2 bg-white dark:bg-gray-800 dark:text-white dark:active:text-black border border-gray-200 dark:border-gray-600 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                disabled={processing}
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-2">
                                Card Details
                            </label>
                            <div className="p-3 border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500">
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "16px",
                                                color: "#424770",
                                                "::placeholder": {
                                                    color: "#aab7c4",
                                                },
                                            },
                                            invalid: {
                                                color: "#9e2146",
                                            },
                                        },
                                        disabled: processing,
                                    }}
                                />
                            </div>
                        </div>

                        <p className="text-sm font-medium">
                            By pressing "Pay" I authorize Sterling
                            Heights to charge my credit card to the amount
                            listed below.
                        </p>

                        <button
                            type="submit"
                            disabled={!stripe || processing}
                            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded disabled:opacity-50 transition-colors"
                        >
                            {processing
                                ? "Processing..."
                                : `Pay $${invoiceData.invoice.total}`}
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

const LoadingState: React.FC = () => (
    <div className="flex justify-center items-center min-h-96">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
);

const ErrorState: React.FC<{ message: string }> = ({ message }) => (
    <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
    >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{message}</span>
    </div>
);

// Main Payment Portal Component
const PaymentPortal: React.FC<PaymentPortalProps> = ({ jobId, stripeKey }) => {
    const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const stripePromise = loadStripe(stripeKey);

    useEffect(() => {
        const fetchInvoice = async (): Promise<void> => {
            try {
                const response = await fetch(`/api/jobs/${jobId}/invoice`);
                if (!response.ok) throw new Error("Failed to fetch invoice");
                const data: InvoiceData = await response.json();
                setInvoiceData(data);
            } catch (err) {
                setError(
                    err instanceof Error ? err.message : "An error occurred"
                );
            } finally {
                setLoading(false);
            }
        };

        fetchInvoice();
    }, [jobId]);

    if (loading) return <LoadingState />;
    if (error) return <ErrorState message={error} />;
    if (!invoiceData) return <ErrorState message="Invoice not found" />;

    return (
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
            <div className="border border-gray-200 dark:border-gray-600 rounded-lg col-span-8">
                <InvoiceDisplay invoiceData={invoiceData} />
            </div>

            <Elements stripe={stripePromise}>
                <PaymentForm invoiceData={invoiceData} jobId={jobId} />
            </Elements>
        </div>
    );
};

export default PaymentPortal;
