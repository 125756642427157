import React, { useEffect, useState } from "react";
import { Customer, Invoice, Job } from "../types/types";
import InvoiceDisplay from "./InvoiceDisplay";

interface InvoiceData {
    customer: Customer;
    invoice: Invoice;
    job: Job;
}

interface PaymentPortalProps {
    jobId: number;
}

const LoadingState: React.FC = () => (
    <div className="flex justify-center items-center min-h-96">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
);

const ErrorState: React.FC<{ message: string }> = ({ message }) => (
    <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
    >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{message}</span>
    </div>
);

const ViewSingleInvoice: React.FC<PaymentPortalProps> = ({ jobId }) => {
    const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchInvoice = async (): Promise<void> => {
            try {
                const response = await fetch(`/api/jobs/${jobId}/invoice`);
                if (!response.ok) throw new Error("Failed to fetch invoice");
                const data: InvoiceData = await response.json();
                setInvoiceData(data);
            } catch (err) {
                setError(
                    err instanceof Error ? err.message : "An error occurred"
                );
            } finally {
                setLoading(false);
            }
        };

        fetchInvoice();
    }, [jobId]);

    if (loading) return <LoadingState />;
    if (error) return <ErrorState message={error} />;
    if (!invoiceData) return <ErrorState message="Invoice not found" />;

    return (
        <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
            <InvoiceDisplay invoiceData={invoiceData} />
        </div>
    );
};

export default ViewSingleInvoice;
