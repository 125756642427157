import React, { useState, useEffect } from "react";
import axios from "axios";

interface Market {
    id: number;
    name: string;
    city: string;
    radius: number;
    google_lat: number;
    google_lng: number;
    google_address: string;
}

interface MarketFinderProps {
    onMarketFound: (marketId: number) => void;
    onNoService: (noService: boolean) => void;
}

const MarketFinder: React.FC<MarketFinderProps> = ({ onMarketFound, onNoService }) => {
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [market, setMarket] = useState<Market | null>(null);
    const [noService, setNoService] = useState(false);

    useEffect(() => {
        const updateAddress = () => {
            const elements = {
                street: document.getElementById("street_1") as HTMLInputElement,
                city: document.getElementById("city") as HTMLInputElement,
                state: document.getElementById(
                    "state_prov"
                ) as HTMLInputElement,
                zip: document.getElementById("postal_code") as HTMLInputElement,
            };

            if (
                elements.street &&
                elements.city &&
                elements.state &&
                elements.zip
            ) {
                const fullAddress = [
                    elements.street.value,
                    elements.city.value,
                    elements.state.value,
                    elements.zip.value,
                ]
                    .filter(Boolean)
                    .join(", ");

                setAddress(fullAddress);
            }
        };

        // Initial update
        updateAddress();

        // Add listeners
        const fields = ["street_1", "city", "state_prov", "postal_code"];
        fields.forEach((id) => {
            document
                .getElementById(id)
                ?.addEventListener("change", updateAddress);
        });

        // Cleanup
        return () => {
            fields.forEach((id) => {
                document
                    .getElementById(id)
                    ?.removeEventListener("change", updateAddress);
            });
        };
    }, []);

    const checkAddress = async () => {
        try {
            setLoading(true);
            setError(null);
            setNoService(false);
            onNoService(false);
            setMarket(null);
            onMarketFound(0);

            // First, geocode the address
            const geocodeResponse = await axios.get(
                `/api/geocode?address=${encodeURIComponent(address)}`
            );
            const { lat, lng } = geocodeResponse.data;

            // Then check if it's within any market's radius
            const marketResponse = await axios.post("/api/check-market", {
                lat,
                lng,
            });

            if (marketResponse.data.market) {
                setMarket(marketResponse.data.market);
                onMarketFound(marketResponse.data.market.id);
            } else {
                setNoService(true);
                onNoService(true);
            }
        } catch (err) {
            setError("Failed to check address. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full mx-auto p-6 border border-gray-200 dark:border-gray-600 dark:text-white rounded-lg">
            <h2 className="text-xl font-semibold mb-4">
                Check Service Availability
            </h2>

            <div className="space-y-4">
                <div>
                    <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                        Enter Your Address
                    </label>
                    <input
                        type="text"
                        id="address"
                        value={address || ""} // Add default empty string
                        onChange={(e) => setAddress(e.target.value)}
                        className="w-full p-2 bg-white dark:bg-gray-800 dark:text-white border border-gray-200 dark:border-gray-600 rounded focus:ring-blue-500 focus:border-blue-500"
                        disabled
                        placeholder="Please enter address above"
                    />
                </div>

                <button
                    onClick={checkAddress}
                    disabled={loading || !address}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
                >
                    {loading ? "Checking..." : "Check Availability"}
                </button>

                {error && <div className="text-red-500 text-sm">{error}</div>}

                {noService && (
                    <div className="bg-red-50 text-red-700 p-4 rounded">
                        Sorry, we don't currently service this area.
                    </div>
                )}

                {market && (
                    <div className="bg-green-50 text-green-700 p-4 rounded">
                        Great news! We service your area through our{" "}
                        {market.name} market.
                    </div>
                )}
            </div>
        </div>
    );
};

export default MarketFinder;
