import React from "react";
import { Customer, Job, Invoice } from "../types/types";

interface InvoiceData {
    customer: Customer;
    job: Job;
    invoice: Invoice;
}

const InvoiceDisplay: React.FC<{ invoiceData: InvoiceData }> = ({
    invoiceData,
}) => {
    const lineItems = Array.isArray(invoiceData.invoice?.line_items)
        ? invoiceData.invoice.line_items
        : [];
    return (
        <div className="flex flex-col justify-center dark:text-white p-4 mb-4 md:col-span-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
                {/* Company Info */}
                <div className="flex items-center gap-4 border border-gray-900 dark:border-gray-600 rounded-lg pr-2 overflow-hidden">
                    <div className="bg-gray-900 dark:bg-gray-600 h-48 w-48 flex items-center justify-center overflow-hidden">
                        <img
                            src="https://sterlingheightsclaims.com/images/sterlingLogo.png"
                            alt="Sterling Logo"
                            className="w-full h-full object-contain"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <p className="text-xs">15525 Kessler St</p>
                        <p className="text-xs">Overland Park, KS 66221</p>
                        <p className="text-xs">
                            (913) 413-5555 / (913) 954-0102
                        </p>
                        <p className="text-xs">
                            office@sterlingheightsclaims.com
                        </p>
                    </div>
                </div>

                {/* Invoice Details */}
                <div className="flex flex-col border border-gray-900 dark:border-gray-600 rounded-lg overflow-hidden">
                    <table className="h-48 w-auto">
                        <tbody>
                            <tr className="border-b border-gray-900 dark:border-gray-600">
                                <td className="bg-gray-900 dark:bg-gray-600 text-white px-4">
                                    Issue Date
                                </td>
                                <td className="p-4">
                                    {formatDate(
                                        invoiceData.invoice.issued_date
                                    )}
                                </td>
                            </tr>
                            <tr className="border-b border-gray-900 dark:border-gray-600">
                                <td className="bg-gray-900 dark:bg-gray-600 text-white px-4">
                                    Due Date
                                </td>
                                <td className="p-4">
                                    {formatDate(invoiceData.invoice.due_date)}
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-gray-900 dark:bg-gray-600 text-white px-4">
                                    Invoice#
                                </td>
                                <td className="p-4">
                                    {invoiceData.invoice.invoice_number}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Billing Info */}
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 border border-gray-900 dark:border-gray-600 rounded-lg overflow-hidden">
                {/* Bill To */}
                <table className="border-r border-gray-900 dark:border-gray-600">
                    <thead>
                        <tr>
                            <th className="flex items-center justify-center bg-gray-900 dark:bg-gray-600 text-white p-4 text-left">
                                BILL TO
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="flex flex-col items-center justify-center p-4">
                                <p className="font-bold">
                                    {invoiceData.customer.parent_customer ??
                                        invoiceData.customer.customer_name}
                                </p>
                                {invoiceData.invoice.billing_address}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* Service Location */}
                <table>
                    <thead>
                        <tr>
                            <th className="flex items-center justify-center bg-gray-900 dark:bg-gray-600 text-white p-4 text-left">
                                SERVICE LOCATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="flex flex-col items-center justify-center p-4">
                                <p className="font-bold">
                                    {invoiceData.customer.customer_name}
                                </p>
                                {invoiceData.job.google_address ?? "N/A"}
                                {invoiceData.customer.customer_phone ?? ""}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Job Info */}
            <div className="grid grid-cols-1 mt-4 border border-gray-900 dark:border-gray-600 rounded-lg overflow-hidden">
                {/* Lines Items */}
                <table>
                    <thead>
                        <tr>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-left">
                                Job#
                            </th>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-right">
                                Date
                            </th>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-right">
                                Claim#
                            </th>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-right">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b border-gray-200 dark:border-gray-600 last:border-b-0">
                            <td className="p-4">{invoiceData.job.id}</td>
                            <td className="p-4 text-right">
                                {formatDate(invoiceData.job.start_date)}
                            </td>
                            <td className="p-4 text-right">
                                {invoiceData.job.claim_number}
                            </td>
                            <td className="p-4 text-right">
                                {
                                    invoiceData.job.vendor_source_data[
                                        "description"
                                    ]
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Line Items and Summary */}
            <div className="grid grid-cols-1 mt-4 border border-gray-900 dark:border-gray-600 rounded-lg overflow-hidden">
                {/* Lines Items */}
                <table>
                    <thead>
                        <tr>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-left">
                                Description
                            </th>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-right">
                                Quantity
                            </th>
                            <th className="bg-gray-900 dark:bg-gray-600 text-white p-4 text-right">
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {lineItems.length > 0 ? (
                            <>
                                {lineItems.map((item, index) => (
                                    <tr
                                        key={index}
                                        className="border-b border-gray-200 dark:border-gray-600 last:border-b-0"
                                    >
                                        <td className="p-4">
                                            {item.description || "N/A"}
                                        </td>
                                        <td className="p-4 text-right">
                                            {item.quantity || 0}
                                        </td>
                                        <td className="p-4 text-right">
                                            {formatCurrency(item.total || 0)}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td
                                    colSpan={3}
                                    className="p-4 text-center text-gray-500"
                                >
                                    No items found
                                </td>
                            </tr>
                        )}
                        <tr className="bg-gray-200 dark:bg-gray-800">
                            <td
                                colSpan={2}
                                className="p-4 text-right font-medium"
                            >
                                Subtotal:
                            </td>
                            <td className="p-4 text-right">
                                {formatCurrency(invoiceData.invoice.subtotal)}
                            </td>
                        </tr>
                        <tr className="bg-gray-200 dark:bg-gray-800">
                            <td
                                colSpan={2}
                                className="p-4 text-right font-medium"
                            >
                                Tax ({invoiceData.invoice.tax_rate}%):
                            </td>
                            <td className="p-4 text-right">
                                {formatCurrency(invoiceData.invoice.tax_amount)}
                            </td>
                        </tr>
                        <tr className="bg-gray-200 dark:bg-gray-800">
                            <td
                                colSpan={2}
                                className="p-4 text-right font-medium"
                            >
                                Processing Fee (2.9% + $0.30):
                            </td>
                            <td className="p-4 text-right">
                                {formatCurrency(
                                    invoiceData.invoice.processing_fee
                                )}
                            </td>
                        </tr>
                        <tr className="bg-gray-900 dark:bg-gray-600 text-white">
                            <td
                                colSpan={2}
                                className="p-4 text-right font-bold"
                            >
                                Total:
                            </td>
                            <td className="p-4 text-right font-bold">
                                {formatCurrency(invoiceData.invoice.total)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvoiceDisplay;

const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(amount);
};

const formatDate = (dateString: string | Date): string => {
    return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};
