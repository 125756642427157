import React, { useState, useEffect, useCallback } from "react";

interface Tech {
    id: number;
    name: string;
    google_address: string;
    google_lat: number;
    google_lng: number;
    updated_at: Date;
}

interface LocationData {
    google_lat: number;
    google_lng: number;
    google_address: string;
    tech_id: number;
    updated_at: string;
}

interface LocationCaptureProps {
    techId: number;
    techs: Tech[];
}

const LocationCapture: React.FC<LocationCaptureProps> = ({ techId, techs }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [showSuccess, setShowSuccess] = useState(true);
    const [locationData, setLocationData] = useState<LocationData | null>(null);
    const [lastUpdateTime, setLastUpdateTime] = useState<Date | null>(null);
    const [canUpdate, setCanUpdate] = useState(false);

    const checkCanRequestLocation = useCallback(() => {
        const tech = techs.find((t) => Number(t.id) === Number(techId));
        if (!tech) return false;

        if (!tech.google_address) return true;
        if (!tech.updated_at) return false;

        const lastUpdate = new Date(tech.updated_at);
        const nextUpdate = new Date(lastUpdate);
        nextUpdate.setHours(lastUpdate.getHours() + 1);

        if (!isNaN(lastUpdate.getTime())) {
            setLastUpdateTime(lastUpdate);
        }

        return new Date() > nextUpdate;
    }, [techId, techs]);

    const getTimeUntilNextUpdate = useCallback((): string => {
        const tech = techs.find((t) => Number(t.id) === Number(techId));

        if (!tech) return "No tech found";
        if (!tech.google_address) return "No location set";
        if (!tech.updated_at) return "Update time information missing";

        const nextUpdate = new Date(tech.updated_at);
        nextUpdate.setHours(nextUpdate.getHours() + 1);

        const now = new Date();
        const diffMs = nextUpdate.getTime() - now.getTime();
        const diffMins = Math.round(diffMs / 60000);

        return diffMins > 0
            ? `Next update available in ${diffMins} minutes`
            : "Update available";
    }, [techId, techs]);

    useEffect(() => {
        const updateStatus = checkCanRequestLocation();
        setCanUpdate(updateStatus);
        if (updateStatus) {
            checkAndRequestLocation(updateStatus);
        }
    }, [techId]);

    const checkAndRequestLocation = async (updateStatus: boolean) => {
        if (!updateStatus) {
            setError(
                `Please wait before updating again. ${getTimeUntilNextUpdate()}`
            );
            return;
        }

        setLoading(true);
        setError(null);

        try {
            if (!navigator.geolocation) {
                throw new Error("Geolocation is not supported by your browser");
            }

            const position = await new Promise<GeolocationPosition>(
                (resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                }
            );

            const response = await fetch(
                `https://api.geoapify.com/v1/geocode/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&apiKey=67718b5929f54d5eaadb782592490ac6`
            );

            const data = await response.json();

            if (!data.features?.[0]?.properties?.formatted) {
                throw new Error("Could not get address from coordinates");
            }

            const now = new Date().toISOString();
            const newLocationData: LocationData = {
                tech_id: techId,
                google_lat: position.coords.latitude,
                google_lng: position.coords.longitude,
                google_address: data.features[0].properties.formatted,
                updated_at: now,
            };

            const saveResponse = await fetch("/api/tech/address", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newLocationData),
            });

            if (!saveResponse.ok) {
                throw new Error("Failed to save location");
            }

            setLocationData(newLocationData);
            setLastUpdateTime(new Date());
            setSuccess(true);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                if (err instanceof GeolocationPositionError) {
                    switch (err.code) {
                        case err.PERMISSION_DENIED:
                            setError(
                                "Please allow location access to continue."
                            );
                            break;
                        case err.POSITION_UNAVAILABLE:
                            setError(
                                "Unable to determine your location. Please try again."
                            );
                            break;
                        case err.TIMEOUT:
                            setError(
                                "Location request timed out. Please try again."
                            );
                            break;
                        default:
                            setError(
                                "Failed to get your location. Please try again."
                            );
                    }
                } else {
                    setError("An unexpected error occurred");
                }
            }
        } finally {
            setLoading(false);
        }
    };

    if (!showSuccess || !canUpdate) return null;

    return (
        <div className="pb-4 space-y-4">
            {loading && (
                <div className="flex items-center justify-center space-x-2">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
                    <span>Getting your location...</span>
                </div>
            )}

            {error && (
                <div className="flex items-center gap-4 bg-red-50 border border-red-200 text-red-700 p-4 rounded">
                    <p>{error}</p>
                </div>
            )}

            {success && locationData && (
                <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded space-y-2 relative">
                    <div className="flex justify-between items-start">
                        <p>Location saved successfully!</p>
                        <button
                            onClick={() => {
                                setShowSuccess(false);
                                setCanUpdate(false);
                            }}
                            className="text-green-700 hover:text-green-900 px-2"
                            aria-label="Close"
                        >
                            ×
                        </button>
                    </div>
                    <div className="text-sm">
                        <p>Address: {locationData.google_address}</p>
                        <p>
                            Coordinates: {locationData.google_lat},{" "}
                            {locationData.google_lng}
                        </p>
                        <p>
                            Last updated:{" "}
                            {new Date(locationData.updated_at).toLocaleString()}
                        </p>
                        {!canUpdate && (
                            <p className="text-gray-600 mt-2">
                                {getTimeUntilNextUpdate()}
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LocationCapture;
