import React, { useState, useEffect } from "react";
import axios from "axios";

interface Tech {
    id: number;
    google_address?: string;
    google_lat?: number;
    google_lng?: number;
}

interface AddressFormData {
    street: string;
    city: string;
    state: string;
    zip: string;
    tech_id: number;
}

interface TechAddressFormProps {
    techId: number;
    techs: Tech[];
}

const TechAddressForm: React.FC<TechAddressFormProps> = ({ techId, techs }) => {
    const [formData, setFormData] = useState<AddressFormData>({
        street: "",
        city: "",
        state: "",
        zip: "",
        tech_id: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);

    useEffect(() => {
        const tech = techs.find((t) => t.id === techId);
        if (tech?.google_address) {
            const addressParts = tech.google_address
                .split(",")
                .map((part) => part.trim());
            setFormData({
                street: addressParts[0] || "",
                city: addressParts[1] || "",
                state: addressParts[2]?.split(" ")[0] || "",
                zip: addressParts[2]?.split(" ")[1] || "",
                tech_id: tech.id,
            });
        } else {
            if (tech) {
                setFormData((prev) => ({ ...prev, tech_id: tech.id }));
            }
        }
    }, [techId, techs]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const hiddenButton = document.getElementById("hiddenLoadingButton");
        if (hiddenButton) {
            hiddenButton.click();
        }
        setLoading(true);
        setError(null);

        try {
            const address = `${formData.street}, ${formData.city}, ${formData.state} ${formData.zip}`;
            const geocodeResponse = await axios.get(
                `/api/geocode?address=${encodeURIComponent(address)}`
            );
            const { lat, lng, formatted_address } = await geocodeResponse.data;

            const response = await fetch("/api/tech/address", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    google_lat: lat,
                    google_lng: lng,
                    google_address: formatted_address,
                    tech_id: formData.tech_id,
                }),
            });

            if (!response.ok) throw new Error("Failed to save address");
            setSubmitted(true);
        } catch (err) {
            setError("Failed to save address. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch("/api/tech/address", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    remove_address: Boolean(true),
                    tech_id: formData.tech_id,
                }),
            });

            if (!response.ok) throw new Error("Failed to save address");
            setDeleted(true);
        } catch (err) {
            setError("Failed to save address. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <form onSubmit={handleSubmit}>
            {submitted ? (
                <div className="bg-green-50 text-green-700 p-4 rounded">
                    Address updated successfully!
                </div>
            ) : deleted ? (
                <div className="bg-red-50 text-red-700 p-4 rounded">
                    Address deleted successfully!
                </div>
            ) : (
                <div className="space-y-4">
                    <div>
                        <label
                            htmlFor="street"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Street Address
                        </label>
                        <input
                            type="text"
                            name="street"
                            id="street"
                            value={formData.street}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                        >
                            City
                        </label>
                        <input
                            type="text"
                            name="city"
                            id="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="state"
                            className="block text-sm font-medium text-gray-700"
                        >
                            State
                        </label>
                        <input
                            type="text"
                            name="state"
                            id="state"
                            value={formData.state}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="zip"
                            className="block text-sm font-medium text-gray-700"
                        >
                            ZIP Code
                        </label>
                        <input
                            type="text"
                            name="zip"
                            id="zip"
                            value={formData.zip}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div className="flex items-center gap-2">
                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
                        >
                            {loading ? "Saving..." : "Save Address"}
                        </button>
                        <button
                            type="button"
                            onClick={handleDelete}
                            disabled={loading}
                            className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 disabled:opacity-50"
                        >
                            {loading ? "Deleting..." : "Delete Address"}
                        </button>
                    </div>
                </div>
            )}

            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        </form>
    );
};

export default TechAddressForm;
