import React, { useState, useEffect } from 'react';

const EmailTemplatesDashboard = () => {
    const [templates, setTemplates] = useState<Template[]>([]);
    interface Template {
        id: number;
        category: string;
        name: string;
        content: string;
    }
    
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
    const [content, setContent] = useState('');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        const response = await fetch('/api/email-templates');
        const data = await response.json();
        setTemplates(data);
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setContent(template.content);
    };

    const handleSave = async () => {
        if (!selectedTemplate) return;
        setSaving(true);
        try {
            await fetch(`/api/email-templates/${selectedTemplate.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ content })
            });
            fetchTemplates();
        } catch (error) {
            console.error('Error saving template:', error);
        }
        setSaving(false);
    };

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Templates List */}
                <div className="border border-gray-200 dark:border-gray-600 p-4 rounded-lg">
                    <h2 className="text-lg font-semibold mb-4 dark:text-white">Email Templates</h2>
                    <div className="space-y-2">
                        {templates.map(template => (
                            <button
                                key={template.id}
                                onClick={() => handleTemplateSelect(template)}
                                className={`w-full text-left p-3 rounded ${
                                    selectedTemplate?.id === template.id
                                        ? 'bg-blue-50 border border-blue-200 dark:text-gray-800'
                                        : 'hover:bg-gray-50 hover:dark:text-gray-800 dark:text-white'
                                }`}
                            >
                                <div className="font-medium">{template.category}</div>
                                <div className="text-sm text-gray-500 dark:text-gray-600">{template.name}</div>
                            </button>
                        ))}
                    </div>
                </div>

                {/* Editor */}
                <div className="md:col-span-2">
                    {selectedTemplate ? (
                        <div className="border border-gray-200 dark:border-gray-600 p-4 rounded-lg">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-semibold dark:text-white">
                                    Editing: {selectedTemplate.category} - {selectedTemplate.name}
                                </h2>
                                <button
                                    onClick={handleSave}
                                    disabled={saving}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                                >
                                    {saving ? 'Saving...' : 'Save Changes'}
                                </button>
                            </div>
                            <textarea
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="w-full h-[600px] p-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded font-mono dark:text-white"
                            />
                        </div>
                    ) : (
                        <div className="dark:text-white border border-gray-200 dark:border-gray-600 p-4 rounded-lg text-center">
                            Select a template to edit
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailTemplatesDashboard;