import React, { useState, useEffect } from "react";
import { CalendarDays, Clock } from "lucide-react";
import MarketFinder from "./MarketFinder";
interface TimeSlot {
    time: string;
    formatted_time: string;
    is_available: boolean;
}
interface Availability {
    total_available: number;
    time_slots: TimeSlot[];
}

const DateTimeSelector = () => {
    const [selectedMarket, setSelectedMarket] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [markets, setMarkets] = useState<{ id: string; name: string }[]>([]);

    const [availableTimeSlots, setAvailableTimeSlots] =
        useState<Availability | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [noService, setNoService] = useState(false);

    // Fetch markets list
    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const response = await fetch("/api/markets/index");
                if (!response.ok) throw new Error("Failed to fetch markets");
                const data = await response.json();
                setMarkets(data);
            } catch (err) {
                setError("Failed to load markets");
                console.error("Error fetching markets:", err);
            }
        };

        fetchMarkets();
    }, []);

    // Fetch availability when market or date changes
    useEffect(() => {
        const fetchAvailability = async () => {
            if (!selectedMarket || !selectedDate) return;

            try {
                setIsLoading(true);
                const response = await fetch(
                    `/api/markets/${selectedMarket}/availability?date=${selectedDate}`
                );
                if (!response.ok)
                    throw new Error("Failed to fetch availability");
                const data = await response.json();
                setAvailableTimeSlots(data);
                setError("");
            } catch (err) {
                setError("Failed to load available time slots");
                console.error("Error fetching availability:", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAvailability();
    }, [selectedMarket, selectedDate]);

    // Handle market selection
    const handleMarketChange = (e) => {
        const value = e.target.value;
        setSelectedMarket(value);
        setSelectedDate("");
        setSelectedTime("");
        setAvailableTimeSlots(null);
    };

    const setFormattedTime = (time) => {
        // Convert from "HH:00:00" format to "HH:00" format
        const formattedTime = time.substring(0, 5);
        setSelectedTime(formattedTime);
    };

    return (
        <div className="space-y-6">
            {error && (
                <div
                    className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                >
                    <span className="block sm:inline">{error}</span>
                </div>
            )}
            <div className="flex items-center gap-4">
                {/* Market Finder */}
                <MarketFinder
                    onMarketFound={(marketId) =>
                        setSelectedMarket(marketId.toString())
                    }
                    onNoService={(noService) => {
                        setNoService(noService);
                    }}
                />
                {noService ? (
                    <div
                        className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative"
                        role="no-service"
                    >
                        <span className="block sm:inline">
                            There is currently no ladder assist service
                            available at your selected location. Please change
                            job type to <strong>Direct/Solo Inspection</strong>.
                        </span>
                    </div>
                ) : (
                    <div className="w-full flex flex-col gap-y-2">
                        {/* Market Selector */}
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
                                Selected Market
                                <span className="text-red-500 ml-1">*</span>
                            </label>
                            <select
                                value={selectedMarket}
                                onChange={handleMarketChange}
                                className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500"
                                disabled
                            >
                                <option value="">Check Availability</option>
                                {markets.map((market) => (
                                    <option key={market.id} value={market.id}>
                                        {market.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Date Selector */}
                        <div className="space-y-2">
                            <label className="flex items-center text-sm font-medium text-gray-700 dark:text-gray-200">
                                <CalendarDays className="w-4 h-4 mr-2" />
                                Select Date
                                <span className="text-red-500 ml-1">*</span>
                            </label>
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => {
                                    setSelectedDate(e.target.value);
                                    setSelectedTime("");
                                }}
                                min={new Date().toISOString().split("T")[0]}
                                className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white dark:fill-white focus:ring-2 focus:ring-blue-500"
                                disabled={isLoading || !selectedMarket}
                            />
                        </div>

                        {/* Time Slots */}
                        <div className="space-y-2">
                            <label className="flex items-center text-sm font-medium text-gray-700 dark:text-gray-200">
                                <Clock className="w-4 h-4 mr-2" />
                                Select Time
                                <span className="text-red-500 ml-1">*</span>
                            </label>
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                                {!selectedMarket ? (
                                    <div className="col-span-full text-start py-2 text-blue-500">
                                        Please check availability first
                                    </div>
                                ) : !selectedDate ? (
                                    <div className="col-span-full text-start py-2 text-blue-500">
                                        Please select a date
                                    </div>
                                ) : isLoading ? (
                                    <div className="col-span-full text-start py-2 text-gray-500">
                                        Loading available time slots...
                                    </div>
                                ) : availableTimeSlots?.total_available === 0 ? (
                                    <div className="col-span-full text-start py-2 text-red-500">
                                        No available time slots for this date
                                    </div>
                                ) : (
                                    availableTimeSlots?.time_slots.map(
                                        (slot) => {
                                            const isSelected =
                                                slot.time.substring(0, 5) ===
                                                selectedTime;

                                            return (
                                                <button
                                                    key={slot.time}
                                                    type="button"
                                                    onClick={() =>
                                                        setFormattedTime(
                                                            slot.time
                                                        )
                                                    }
                                                    disabled={
                                                        !slot.is_available
                                                    }
                                                    className={`
                                    p-4 rounded-lg text-sm font-medium transition-colors relative border border-gray-200 dark:border-gray-600
                                    ${
                                        !slot.is_available
                                            ? "bg-gray-100 dark:bg-gray-700 text-gray-400 dark:text-gray-200 cursor-not-allowed"
                                            : isSelected
                                            ? "bg-blue-500 text-white hover:bg-blue-600"
                                            : "bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                                    }
                                `}
                                                >
                                                    <div className="font-bold">
                                                        {slot.formatted_time}
                                                    </div>
                                                </button>
                                            );
                                        }
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Hidden inputs for form submission */}
            <input
                type="hidden"
                name="job_data[market_id]"
                value={selectedMarket}
                disabled={noService}
            />
            <input
                type="hidden"
                name="job_data[ladder_date]"
                value={selectedDate}
                required={!!selectedMarket}
                disabled={noService}
            />
            <input
                type="hidden"
                name="job_data[ladder_time]"
                value={selectedTime}
                required={!!selectedDate}
                disabled={noService}
            />
        </div>
    );
};

export default DateTimeSelector;
